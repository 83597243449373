export const tagLocales = {
  react: 'React',
  prismic: 'Prismic',
  jamstack: 'jamstack',
  gatsby: 'Gatsby',
  seo: 'SEO',
  cambodia: 'Cambodia',
  hotel: 'Hotel',
  marketing: 'Marketing',
  meo: 'MEO',
  remittance: 'Remittance',
  france: 'France',
  favoriteproduct2020: 'FavoriteProduct2020',
  vacances: 'Vacances'
};

export const categoryLocales = {
  life: 'Life',
  startup: 'Startup',
  development: 'Development',
  travel: 'Travel',
  language: 'Language',
  eat: 'Eat',
  nba: 'NBA',
};
