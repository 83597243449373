import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { tagLocales, categoryLocales } from '../locales/locales';
import { dateSort } from '../utils/sort';
import '../scss/sitemap.scss';

export const query = graphql`
  {
    prismic {
      allArticles {
        edges {
          node {
            updated_at
            posted_at
            article_title
            _meta {
              uid
              tags
              lang
            }
            categories {
              category {
                ... on PRISMIC_Category {
                  name
                  _linkType
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Section = styled.section`
  margin: 20px auto 60px auto;
  padding: 0 20px;
  max-width: 680px;
`;

const Articles = props => {
  const categories = [
    'life',
    'startup',
    'development',
    'language',
    'travel',
    'nba',
    'eat',
  ];
  const articles = props.data.prismic.allArticles.edges;
  const dateSortArticle = articles.sort(dateSort());
  const filteredArticles = dateSortArticle.filter(d => {
    return d.node._meta.uid !== 'privacy' && d.node._meta.uid !== 'law';
  });
  return (
    <Body>
      <SEO
        title="サイトマップ"
        url={props.location.href}
        description="サイトマップについて"
      />
      <Layout>
        <Section>
          <h1>サイトマップ</h1>
          {categories.map(category => {
            const categoryArticles = filteredArticles.filter(
              c =>
                c.node.categories[0].category.name === categoryLocales[category]
            );
            return (
              <div key={category} className="category_area">
                <h3>{categoryLocales[category]}</h3>
                <ul>
                  {categoryArticles.map(article => {
                    const articleTitle = article.node.article_title[0].text;
                    const link = article.node._meta.uid;
                    const postedAt = article.node.posted_at;
                    const updatedAt = article.node.updated_at;
                    const tags = article.node._meta.tags;
                    return (
                      <li key={link}>
                        <div className="article_title">
                          <Link to={`/${link}/`}>{articleTitle}</Link>
                        </div>
                        <div className="day">
                          <span className="posted_at">{postedAt}</span>
                          {!!updatedAt && (
                            <span className="updated_at">更新:{updatedAt}</span>
                          )}
                        </div>
                        {tags.length > 0 &&
                          tags.map(tag => {
                            const tagLowerCase = tag.toLowerCase();
                            const IndicatedTag = tagLocales[tagLowerCase];
                            return (
                              <span key={tag} className="tags">
                                <Link to={`/tags/${tagLowerCase}/`}>
                                  #{IndicatedTag}
                                </Link>
                              </span>
                            );
                          })}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </Section>
      </Layout>
    </Body>
  );
};

export default Articles;
